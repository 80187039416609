<template>
  <div>
    <Snackbar :SnackBarComponent="SnackBarComponent" />
    <v-dialog persistent width="1000px" v-model="AddMasterCategorydialog">
      <v-card :height="windowHeight">
        <v-toolbar class="primary elevation-0" dense dark>
          <v-toolbar-title class="ml-2 white--text">{{
            StoreObj.action == "CREATE"
              ? "Add Master Category"
              : "Edit Master Category"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            large
            class="mr-n4"
            dark
            text
            @click="AddMasterCategorydialogEmit((Toggle = 1))"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4 pb-0">
          <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="4">
              <v-form ref="form">
                <v-text-field
                  outlined
                  dense
                  label="Master Category Name *"
                  class="FontSize field_label_size field_height"
                  :rules="[(v) => !!v || 'Required']"
                  v-model="category_name"
                ></v-text-field>
                <v-select
                  label="Country Name *"
                  v-model="country_name"
                  :items="CountryList"
                  item-value="name"
                  item-text="name"
                  dense
                  outlined
                  :rules="[(v) => !!v || 'Required']"
                  class="FontSize field_label_size field_height"
                >
                </v-select>
                <v-text-field
                  outlined
                  dense
                  label="Category Ticket Prefix *"
                  class="FontSize field_label_size field_height"
                  hint="Ticket Prefix must be 3 Characters"
                  persistent-hint
                  :rules="[(v) => !!v || 'Required']"
                  v-model="category_ticket_prefix"
                  @keyup="callmethodUppercase(category_ticket_prefix)"
                ></v-text-field>
                <div class="font-weight-bold">Selected Icon</div>
                <div align="center" v-if="category_icon_unicode == ''">
                  No Icon Selected
                </div>
                <div align="center">
                  <v-img :src="category_icon_unicode" max-width="100px"></v-img>
                </div>
              </v-form>
              <v-card-actions class="pt-4 pr-0">
                <v-spacer></v-spacer>
                <v-btn
                  small
                  :loading="isLoading"
                  @click="validateMethod()"
                  class="mr-2 secondary white--text"
                  ><span
                    v-text="StoreObj.action == 'CREATE' ? 'ADD' : 'EDIT'"
                  ></span
                ></v-btn>
              </v-card-actions>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="8">
              <v-card class="ml-8 elevation-1">
                <v-toolbar dense class="py-0 elevation-0"
                  >Select Icon
                  <v-spacer></v-spacer>
                  <v-select
                    dense
                    outlined
                    class="FontSize field_label_size field_height mt-6"
                    :items="commonPrefixes"
                    item-text="Name"
                    item-value="Prefix"
                    v-model="icon_category"
                    label="Select Catagory"
                  ></v-select>
                </v-toolbar>
                <v-card
                  :height="CardHeight"
                  class="overflow-y-auto elevation-0"
                >
                  <v-card-text>
                    <v-row>
                      <v-col
                        v-for="(icon, idx) in IconsList"
                        :key="idx"
                        cols="2"
                        @click="checkItem(icon)"
                      >
                        <v-img :src="icon.URL"></v-img>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Snackbar from "@/components/Extras/Snackbar.vue";
import CountryList from "@/mixins/CountryList.json";
import { API, graphqlOperation } from "aws-amplify";
import { GetAllIcons } from "@/mixins/GetAllIcons.js";
import { CreateMasterCategory, UpdateCategory } from "@/graphql/mutations.js";
export default {
  components: {
    Snackbar,
  },
  props: {
    StoreObj: Object,
    AddMasterCategorydialog: Boolean,
  },
  mixins: [GetAllIcons],
  data: () => ({
    CardHeight: 0,
    windowHeight: 0,
    isLoading: false,
    country_name: "",
    icon_category: "",
    category_name: "",
    category_icon_unicode: "",
    category_ticket_prefix: "",
    IconsList: [],
    CountryList: [],
    commonPrefixes: [],
    SnackBarComponent: {},
  }),
  mounted() {
    this.CountryList = CountryList;
    this.windowHeight = window.innerHeight - 70;
    this.CardHeight = window.innerHeight - 230;
  },
  watch: {
    icon_category(val) {
      this.GetAllIconsMethod(val, "FILE");
    },
    AddMasterCategorydialog(val) {
      if (val == true) {
        this.GetAllIconsMethod("servicewrkiconsgallery/", "FOLDER");
        if (this.StoreObj.action == "EDIT") {
          this.category_name = this.StoreObj.category_name;
          this.category_icon_unicode = this.StoreObj.category_icon_unicode;
          this.country_name = this.StoreObj.country_name;
          this.category_ticket_prefix = this.StoreObj.category_ticket_prefix;
        }
      }
    },
  },
  methods: {
    checkItem(item) {
      this.category_icon_unicode = item.URL;
    },
    callmethodUppercase(category_ticket_prefix) {
      this.category_ticket_prefix = category_ticket_prefix.toUpperCase();
    },
    validateMethod() {
      if (
        this.$refs.form.validate() &&
        this.category_icon_unicode != "" &&
        this.category_icon_unicode != null &&
        this.category_icon_unicode != undefined
      ) {
        if (this.StoreObj.action == "CREATE") {
          this.createMasterCategory();
        } else if (this.StoreObj.action == "EDIT") {
          this.updateMasterCategory();
        }
      } else {
        if (
          this.category_icon_unicode == null ||
          this.category_icon_unicode == undefined ||
          this.category_icon_unicode == ""
        ) {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Please Select Category Icon",
          };
        } else {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "red",
            Top: true,
            SnackbarText: "Fill all the required details",
          };
        }
      }
    },
    async updateMasterCategory() {
      this.isLoading = true;
      try {
        var inputParams = {
          updater_user_id: this.$store.getters.get_current_user_details.user_id,
          category_id: this.StoreObj.category_id,
          category_name: this.category_name,
          category_ticket_prefix: this.category_ticket_prefix,
          category_icon_unicode: this.category_icon_unicode,
        };
        let result = await API.graphql(
          graphqlOperation(UpdateCategory, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.UpdateCategory);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.AddMasterCategorydialogEmit((this.Toggle = 2));
        }
      } catch (error) {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
        this.isLoading = false;
      }
    },
    async createMasterCategory() {
      this.isLoading = true;
      try {
        var inputParams = {
          creator_user_id: this.$store.getters.get_current_user_details.user_id,
          country_name: this.country_name,
          category_icon_unicode: this.category_icon_unicode,
          category_name: this.category_name,
          category_ticket_prefix: this.category_ticket_prefix,
        };
        let result = await API.graphql(
          graphqlOperation(CreateMasterCategory, {
            input: inputParams,
          })
        );
        var ResultObject = JSON.parse(result.data.CreateMasterCategory);
        if (ResultObject.status == "Success") {
          this.SnackBarComponent = {
            SnackbarVmodel: true,
            SnackbarColor: "green",
            Top: true,
            SnackbarText: ResultObject.Status_Message,
          };
          this.AddMasterCategorydialogEmit((this.Toggle = 2));
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: error.errors[0].message,
        };
      }
    },
    AddMasterCategorydialogEmit(Toggle) {
      this.isLoading = false;
      this.category_icon_unicode = "";
      if (this.$refs.form != undefined) {
        this.$refs.form.reset();
      }
      this.$emit("clicked", Toggle);
    },
  },
};
</script>
