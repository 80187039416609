<template>
  <div>
    <Overlay :overlay="overlay" />
    <AddMasterCategory
      :StoreObj="StoreObj"
      :AddMasterCategorydialog="AddMasterCategorydialog"
      @clicked="AddMasterCategorydialogEmit"
    />
    <DeleteCategory
      :StoreObj="StoreObj"
      :dialogDeleteCategory="dialogDeleteCategory"
      @clicked="dialogDeleteCategoryEmit"
    />
    <v-card class="elevation-0">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="6" xs="12">
          <v-toolbar dense class="elevation-0">
            <div class="PrimaryFontColor heading1">Master Category</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" sm="12" md="6" xs="12">
          <v-toolbar dense class="elevation-0">
            <v-spacer></v-spacer>
            <v-select
              outlined
              dense
              class="FontSize mt-9 maxWidthSmall field_label_size field_height mr-2"
              label="Status"
              v-model="category_status"
              :items="StatusItems"
            ></v-select>
            <v-text-field
              dense
              outlined
              class="FontSize mt-9 field_label_size field_height mr-2"
              label="Search"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-tooltip color="primary" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="
                    (AddMasterCategorydialog = true),
                      (StoreObj.action = 'CREATE')
                  "
                  v-on="on"
                  small
                  v-bind="attrs"
                  class="ml-2 mt-3 primary white--text"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              <span class="white--text">Add Master Category</span>
            </v-tooltip>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-card-text class="py-0">
        <v-row no-gutters>
          <v-col cols="12" sm="12" md="12" xs="12">
            <v-data-table
              dense
              :search="search"
              :headers="headers"
              :no-data-text="noDataText"
              :items="GetAllMasterCategoriesList"
              :footer-props="{
                'items-per-page-options': [100, 200, 500],
              }"
              :items-per-page="100"
              fixed-header
              :height="TableHeight"
            >
              <template v-slot:[`item.category_name`]="{ item }">
                <div class="FontSize">{{ item.category_name }}</div>
              </template>
              <template v-slot:[`item.category_icon_unicode`]="{ item }">
                <v-img
                  max-width="30px"
                  :src="item.category_icon_unicode"
                ></v-img>
              </template>
              <template v-slot:[`item.category_ticket_prefix`]="{ item }">
                <div class="FontSize">{{ item.category_ticket_prefix }}</div>
              </template>
              <template v-slot:[`item.Actions`]="{ item }">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <a @click="checkItem(item, 'EDIT')">
                      <v-icon
                        color="primary"
                        v-if="item.category_status == 'ACTIVE'"
                        small
                        v-on="on"
                        class="mr-3"
                      >
                        mdi-pencil-plus
                      </v-icon>
                    </a>
                  </template>
                  <span class="white--text"
                    >Update Master Category Details</span
                  >
                </v-tooltip>
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      @click="checkItem(item, 'DELETE')"
                      :color="
                        item.category_status == 'ACTIVE' ? 'red' : 'green'
                      "
                      small
                      v-on="on"
                      class="red--text"
                    >
                      {{
                        item.category_status == "ACTIVE"
                          ? "mdi-close-thick"
                          : "mdi-check"
                      }}
                    </v-icon>
                  </template>
                  <span class="white--text">{{
                    item.category_status == "ACTIVE"
                      ? "De-Activate this Master Category ?"
                      : "Activate this Master Category ?"
                  }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { GetAllCategories } from "@/mixins/GetAllCategories.js";
import Overlay from "@/components/Extras/Overlay.vue";
import DeleteCategory from "@/components/Category/Dialogs/DeleteCategory.vue";
import AddMasterCategory from "@/components/MasterCategory/Dialogs/AddMasterCategory.vue";
export default {
  components: {
    Overlay,
    DeleteCategory,
    AddMasterCategory,
  },
  mixins: [GetAllCategories],
  data: () => ({
    TableHeight : 0,
    search: "",
    category_status: "ACTIVE",
    overlay: false,
    dialogDeleteCategory: false,
    AddMasterCategorydialog: false,
    StoreObj: {},
    StatusItems: ["ACTIVE", "INACTIVE"],
    headers: [
      { text: "", value: "category_icon_unicode", width: "30px" },
      { text: "Master Category Name", value: "category_name" },
      { text: "Ticket Prefix", value: "category_ticket_prefix" },
      { text: "Actions", value: "Actions" },
    ],
  }),
  watch: {
    category_status() {
      this.GetAllCategoriesMethod(this.category_status, "MASTER_CATEGORY");
    },
  },
  mounted() {
    this.TableHeight = window.innerHeight - 200;
    this.GetAllCategoriesMethod(this.category_status, "MASTER_CATEGORY");
  },
  methods: {
    dialogDeleteCategoryEmit(Toggle) {
      this.dialogDeleteCategory = false;
      if (Toggle == 2) {
        this.GetAllCategoriesMethod(this.category_status, "MASTER_CATEGORY");
      }
    },
    AddMasterCategorydialogEmit(Toggle) {
      this.AddMasterCategorydialog = false;
      if (Toggle == 2) {
        this.GetAllCategoriesMethod(this.category_status, "MASTER_CATEGORY");
      }
    },
    checkItem(item, action) {
      this.StoreObj = item;
      this.StoreObj.action = action;
      if (action == "EDIT") {
        this.AddMasterCategorydialog = true;
      } else if (action == "DELETE") {
        this.dialogDeleteCategory = true;
      }
    },
    // openAddOrgsdialog() {
    //   this.AddMasterCategorydialog = true;
    // },
    // closeorgdialog() {
    //   this.AddMasterCategorydialog = false;
    //   this.getMasterCategory("ACTIVE");
    // },
  },
};
</script>
